<template>
  <div class="flexMain">
    <div class="filter" v-show="!filterHidden && !fullScreen">
      <div v-if="showAll">
        <select
          class="filterField"
          v-model="city"
          id="sCity"
          :disabled="!showAll"
          @change="saveParams"
        >
          <option value="Все города">Все города</option>
          <option v-for="(city, key) in cities" :key="key" :value="city.city">
            {{ city.city }}
          </option>
        </select>
        <input
          name="address"
          class="filterField address"
          placeholder="Адрес или индекс"
          type="text"
          v-model.trim="address"
          :disabled="!showAll"
          @change="saveParams"
        />
        <el-select
          class="selFormat"
          v-model="format"
          style="width: 100%; padding-left: 0;"
          clearable
          multiple
          placeholder="Фильтр по форматам"
          :disabled="!showAll"
          @change="saveParams"
        >
          <el-option
            v-for="(format, key) in formats"
            :key="key"
            :label="format"
            :value="format"
          > {{ format }}
          </el-option>
        </el-select>
      </div>
      <select
        class="filterField"
        v-model="sort"
        name="select_sort"
        id="sSort"
        @change="saveParams"
      >
        <option v-for="(sort, key) in sorts" :key="key" :value="sort">
          {{ sort }}
        </option>
      </select>
      <div class="listCities">
        <div v-for="(city, key) in sortedPlaces" :key="key">
          <label class="city">
            <input
              v-if="showAll"
              type="checkbox"
              @change="selectCity(city)"
              v-model="city.checked"
            />
            {{ city.city }}
          </label>
          <div class="listPlaces">
            <div
              class="places"
              :class="{ visiblePlace: place.visible }"
              v-for="(place, key) in city.places"
              :key="key"
            >
              <input
                type="checkbox"
                :title="place.format"
                v-model="place.selected"
                :id="place.index"
                @change="selectPlace(city)"
              />
              <label class="label" :for="place.index" :title="place.address">
                <strong :title="place.title">{{ place.index }}</strong>
                {{ place.address }}
              </label>
              <img class="favor"
                src="/images/favor.svg"
                @click="toggleFavor(place)"
                v-if="place.favor"
              />
              <img
                src="/images/noFavor.svg"
                @click="toggleFavor(place)"
                v-if="!place.favor"
              />
              <img
                class="eye"
                src="/images/eye.svg"
                title="Перейти"
                v-if="!place.visible"
                @click="gotoPlace(place, true)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="videoContainer">
      <img
        class="buttonFilter"
        src="/images/filter.svg"
        @click="toggleFilterHidden()"
        v-show="!fullScreen"
      />
      <div class="videoBlock" v-show="!miniWidth || filterHidden">
        <div class="video" v-for="(place, key) in visibledPlaces" :key="key">
          <div class="videoTitle">
            <input
              type="checkbox"
              v-model="place.place.selected"
              :id="place.place.index"
              @change="saveParams"
            />
            <label :for="place.place.index"> {{ place.city }} </label>
            <img
              src="/images/favor.svg"
              @click="toggleFavor(place.place)"
              v-if="place.place.favor"
            />
            <img
              src="/images/noFavorWhite.svg"
              @click="toggleFavor(place.place)"
              v-if="!place.place.favor"
            />
          </div>
          <video-player
            class="videoPlayer"
            :options="place.options"
            :id="'video' + key"
            :title="place.city + ', ' + place.place.address"
            @playerReady="playerReady"
          >
          </video-player>
          <div class="signature" :title="place.place.address">
            <strong>{{ place.place.index }}</strong> {{ place.place.address }}
          </div>
        </div>
      </div>
      <div class="footer" v-show="(!miniWidth || filterHidden) && !fullScreen">
        <div class="buttons">
          <button class="showSelected" @click="showSelected()">
            {{ showAll || showFavor ? "ВЫБРАННЫЕ (" + countSelected + ")" : "ВСЕ" }}
          </button>
          <button class="showSelected" @click="showFavorites()">
            {{showFavor ? "ВСЕ" : "ИЗБРАННОЕ (" + countFavor + ")"}}
          </button>
        </div>
        <v-pagination
          class="pagination"
          v-if="countVideos * qtyStrings < countPlaces"
          :length="countPages"
          total-visible="7"
          v-model="page"
          circle
        ></v-pagination>
        <v-pagination
          class="paginationMini"
          v-if="countVideos * qtyStrings < countPlaces"
          :length="countPages"
          total-visible="0"
          v-model="page"
          circle
        ></v-pagination>
        <label>
          <v-progress-circular class="progress" title="Остановить автопросмотр" :value="curProgress / autoShowSec * 100" size="24" :rotate="-90" width="3" v-if="autoShow" @click="stopShow"> <b>||</b>
          </v-progress-circular>
          <img class="autoShow" src="/images/clockRewind.svg" title="Автопросмотр СТАРТ" v-if="(countPages > 1) && !autoShow" @click="startShow">
          <select name="autoShow" id="autoShow" v-model="autoShowSec"  v-if="countPages > 1" @change="saveParams">
            <option value="10">10 сек.</option>
            <option value="20">20 сек.</option>
            <option value="30">30 сек.</option>
            <option value="45">45 сек.</option>
            <option value="60">60 сек.</option>
          </select>
          <span :title="'В' + (showPreview ? 'ы' : '') + 'ключить отображение превью'">
            <svg 
              class="preview" 
              width="34" 
              height="25" 
              viewBox="0 0 34 25" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg" 
              @click="setShowPreview" 
            >
              <path d="M2.07621 0C1.46124 0 1 0.464511 1 1.08387V22.9161C1 23.5355 1.46124 24 2.07621 24H17.2582H32.4402C33.0552 24 33.5164 23.5355 33.5164 22.9161V1.08387C33.5164 0.464511 33.0552 0 32.4402 0H2.07621Z" :fill="showPreview ? '#15ABF5' : '#C4C4C4'"/>
              <path d="M23.0018 10.3222C21.0431 10.3222 19.5266 8.7313 19.5266 6.8222C19.5266 4.91311 21.1063 3.3222 23.0018 3.3222C24.8974 3.3222 26.477 4.91311 26.477 6.8222C26.477 8.7313 24.8974 10.3222 23.0018 10.3222ZM23.0018 4.91311C21.9908 4.91311 21.1694 5.74038 21.1694 6.75856C21.1694 7.77675 21.9908 8.60402 23.0018 8.60402C24.0128 8.60402 24.8342 7.77675 24.8342 6.75856C24.8342 5.74038 24.0128 4.91311 23.0018 4.91311Z" fill="white"/>
              <path d="M23.0018 4.91311C21.9908 4.91311 21.1694 5.74038 21.1694 6.75856C21.1694 7.77675 21.9908 8.60402 23.0018 8.60402C24.0128 8.60402 24.8342 7.77675 24.8342 6.75856C24.8342 5.74038 24.0128 4.91311 23.0018 4.91311Z" fill="white"/>
              <path d="M26.8817 23.9386L10.7627 9.05803L1.17236 18.0761L1.17242 15.1448L10.7627 6.27094L29.7922 23.9386H26.8817Z" fill="white" stroke="white"/>
              <path d="M33.1878 20.0302L28.1354 15.4834L23.2156 19.5866L21.9088 17.9608L28.1354 12.8511L33.1878 17.5875V20.0302Z" fill="white" stroke="white"/>
            </svg>
          </span>
          <img src="/images/showFor.svg" title="Показывать в строке по:" v-if="maxCountVideos > 1">
          <select
            v-model="countVideos"
            v-if="maxCountVideos > 1"
            name="countVideo"
            id="countVideo"
            @change="saveParams"
          >
            <option v-for="n in maxCountVideos" :key="n" :value="n">
              {{ n }}
            </option>
          </select>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "./videoJS.vue";
import $ from "jquery";
export default {
  name: "Broadcast",
  components: {
    VideoPlayer,
  },
  props: {},
  data() {
    return {
      countVideos: "3",
      cities: [],
      city: "",
      address: "",
      formats: [],
      format: [],
      sort: "Сортировать по умолчанию",
      sorts: [
        "Сортировать по умолчанию",
        "Сортировать по индексу",
        "Сортировать по адресу",
        "Сортировать по формату",
      ],
      showAll: true,
      showFavor: false,
      page: 1,
      sortedPlaces: [],
      qtyStrings: 1,
      maxCountVideos: 5,
      filterHidden: !!localStorage.getItem("filterHidden"),
      miniWidth: false,
      autoShow: false,
      autoShowSec: localStorage.getItem("autoShowSec") ?? 60,
      curProgress: 0,
      tAutoShow: null,
      showPreview: true,
      fullScreen: false,
    };
  },
  computed: {
    visibledPlaces() {
      let i = 0;
      let currentPlace = 0;
      let result = [];
      for (let city of this.sortedPlaces) {
        for (let place of city.places) {
          place.visible = false;
          if (
            currentPlace++ >=
              (this.page - 1) * this.countVideos * this.qtyStrings &&
            i < this.countVideos * this.qtyStrings
          ) {
            place.visible = true;
            result[i++] = {
              place: place,
              city: city.city,
              options: {
                autoplay: true,
                muted: true,
                controls: false,
                poster: this.showPreview ? place.poster : null,
                sources: [
                  {
                    src: place.camera_url,
                    type: "application/x-mpegURL",
                  },
                ],
              },
            };
          }
        }
      }
      return result;
    },
    countPlaces() {
      let result = 0;
      this.sortedPlaces.forEach((city) => {
        result += city.places.length;
      });
      return result;
    },
    countPages() {
      return (
        Math.floor(this.countPlaces / this.countVideos / this.qtyStrings) +
        (this.countPlaces % (this.countVideos * this.qtyStrings) ? 1 : 0)
      );
    },
    countFavor() {
      return this.cities.reduce((count, city) => {
        return count + city.places.reduce((count, place) => {
          return place.favor ? ++count : count;
        }, 0)
      }, 0);
    },
    countSelected() {
      return this.cities.reduce((count, city) => {
        return count + city.places.reduce((count, place) => {
          return place.selected ? ++count : count;
        }, 0)
      }, 0);
    },
  },
  mounted() {
    this.getJSON();
    // $(this.changeWindowSize());
    // setTimeout(() => {
    //   this.changeWindowSize();
    // }, 700);
    window.onresize = this.changeWindowSize;
    document.addEventListener("keyup", this.navigation);
  },
  watch: {
    countPages(newValue) {
      this.stopShow();
      if (this.page > newValue) {
        this.page = Math.max(newValue, 1);
      }
    },
    // **** Сделаны эти отслеживаемые параметры вместо одного вычисляемого sortedPlaces специально для того,
    // **** чтобы можно было вычислить city.checked не обновляя при этом sortedPlaces, что вызывает перезаг-
    // **** рузку всех плейеров почти при каждом выборе места. Т.е. здесь не вызывается метод пересчёта
    // **** sortedPlaces при изменении place.selected, как это было бы при вычисляемом поле.
    city() {
      this.calcSortedPlaces();
    },
    format() {
      this.calcSortedPlaces();
    },
    sort(newValue, oldValue) {
      let currentPlace = this.getCurrentPlace(oldValue);
      this.calcSortedPlaces();
      this.gotoPlace(currentPlace);
    },
    address() {
      this.calcSortedPlaces();
    },
    showAll() {
      this.calcSortedPlaces();
    },
    showFavor() {
      this.calcSortedPlaces();
    },
    // *****************************************************************************************************
    countVideos() {
      let currentPlace = this.getCurrentPlace();
      this.changeWindowSize();
      this.gotoPlace(currentPlace);
    },
    filterHidden(newValue) {
      let gotoNewPlace =
        document.querySelector(".videoContainer").style.flexGrow == 3;
      let currentPlace = this.getCurrentPlace();
      document.querySelector(".buttonFilter").style.marginLeft = newValue
        ? "0"
        : "-10px";
      this.saveParams();
      setTimeout(() => {
        this.changeWindowSize();
        if (!gotoNewPlace) this.gotoPlace(currentPlace);
      }, 1);
    },
    autoShowSec() {
      this.stopShow();
    },
  },
  methods: {
    playerReady() {
      if (this.playersReady) return;
      this.playersReady = true;
      this.changeWindowSize();
    },
    calcSortedPlaces() {
      this.stopShow();
      let filterCity = this.city !== "Все города";
      this.sortedPlaces = [];
      let conditionAddress = this.address.split(" ");
      this.cities.forEach((city) => {
        let pushElement =
          !filterCity || city.city === this.city || !this.showAll;
        if (pushElement) {
          this.sortedPlaces.push({
            city: city.city,
            places: [],
          });
          let curCity = this.sortedPlaces[this.sortedPlaces.length - 1];
          city.places.forEach((place) => {
            let pushPlace = !this.format.length || this.format.includes(place.format);
            conditionAddress.forEach((element) => {
              pushPlace &&=
                place.index.toLowerCase().indexOf(element.toLowerCase()) !==
                  -1 ||
                place.address.toLowerCase().indexOf(element.toLowerCase()) !==
                  -1;
            });
            if (
              (this.showAll && pushPlace) ||
              (!this.showAll && (this.showFavor ? place.favor : place.selected))
            ) {
              curCity.places.push(place);
            }
          });
          curCity.checked = curCity.places.reduce(
            (checked, place) => checked && place.selected,
            true
          );
        }
      });
      for (let i = 0; i < this.sortedPlaces.length; ) {
        let city = this.sortedPlaces[i];
        if (!city.places.length) {
          this.sortedPlaces.splice(i, 1);
        } else {
          i++;
          city.places.sort((s, t) => {
            let a, b, ta, tb;
            switch (this.sort) {
              case "Сортировать по умолчанию":
                a = s.id;
                b = t.id;
                break;
              case "Сортировать по индексу":
                a = s.index.trim().toLowerCase();
                b = t.index.trim().toLowerCase();
                break;
              case "Сортировать по адресу":
                a = s.address.trim().toLowerCase();
                b = t.address.trim().toLowerCase();
                break;
              case "Сортировать по формату":
                ta = s.format.split("x");
                tb = t.format.split("x");
                a = ta[0] * ta[1];
                b = tb[0] * tb[1];
                break;
            }
            if (a > b) return 1;
            if (b > a) return -1;
            return 0;
          });
        }
      }
    },
    selectCity(city) {
      city.places.forEach((place) => {
        place.selected = city.checked;
      });
      this.saveParams();
    },
    selectPlace(city) {
      city.checked = true;
      city.places.forEach((place) => {
        city.checked &&= place.selected;
      });
      this.saveParams();
    },
    async getJSON() {
      let hashes = window.location.search.replace("?", "").split("&"),
        params = [];
      for (let i = 0; i < hashes.length; i++) {
        let hash = hashes[i].split("=");
        params.push(hash[0].toLowerCase());
        params[hash[0].toLowerCase()] = decodeURI(hash[1]);
      }
      let isFilter = params["city"] || params["address"] || params["format"];
      this.city =
        params["city"] ??
        (isFilter
          ? "Все города"
          : localStorage.getItem("city") ?? "Все города");
      this.city =
        this.city.charAt(0).toUpperCase() + this.city.slice(1).toLowerCase();
      this.address =
        params["address"]?.replace("+", " ") ??
        (isFilter ? "" : localStorage.getItem("address") ?? "");
      this.cities = [];
      this.format =
        params["format"]?.split(',') ??
        (isFilter
          ? []
          : localStorage.getItem("format") ?? []);
      if (typeof this.format === 'string') this.format = this.format.split(',');
      this.format = this.format.map(f => f.replace("х", "x").replace("*", "x")); //русскую букву на английскую
      this.formats = [];
      this.countVideos = localStorage.getItem("countVideos") ?? "3";
      this.showPreview = localStorage.getItem("showPreview") === "false" ? false : true;
      let selected = params["clearselected"]
        ? ""
        : localStorage.getItem("selectedPlaces") ?? "";
      let favorites = params["clearfavor"]
        ? ""
        : localStorage.getItem("favorites") ?? "";
      switch (params["show"]) {
        case "all":
          this.showAll = true;
          this.showFavor = false;
          break;
        case "selected":
          this.showAll = false;
          this.showFavor = false;
          break;
        case "favor":
          this.showAll = false;
          this.showFavor = true;
          break;
        default:
          this.showAll =
            localStorage.getItem("showAll") === "false" ? false : true;
          this.showFavor =
            localStorage.getItem("showFavor") === "true" ? true : false;
          break;
      }
      switch (params["sort"]) {
        case "address":
          this.sort = "Сортировать по адресу";
          break;
        case "index":
          this.sort = "Сортировать по индексу";
          break;
        case "format":
          this.sort = "Сортировать по формату";
          break;
        default:
          this.sort = "Сортировать по умолчанию";
          break;
      }
      let prom = fetch(
        `${ process.env.VUE_APP_API_URL }`
      ).then((response) => response.json());
      prom.then((commits) => {
        commits.data.forEach((city) => {
          let findCity = this.cities.find((x) => x.city === city.city);
          if (!findCity) {
            this.cities.push({
              city: city.city,
              places: [],
            });
            findCity = this.cities[this.cities.length - 1];
          }
          let placeSelected = selected.indexOf(city.place_index) !== -1;
          let favor = favorites.indexOf(city.place_index) !== -1;
          findCity.places.push({
            address: city.address,
            index: city.place_index,
            format: city.format,
            camera_url: city.camera_url,
            poster: city.photo_list[0],
            title: city.direction,
            id: findCity.places.length,
            selected: placeSelected,
            visible: false,
            favor: favor,
          });
          findCity.checked &&= placeSelected;
          if (!this.formats.find((x) => x === city.format)) {
            this.formats.push(city.format);
          }
        });
        this.formats.sort((s, t) => {
          let a = s.split("x");
          let b = t.split("x");
          let sa = a[0] * a[1];
          let sb = b[0] * b[1];
          if (sa > sb) return 1;
          if (sb > sa) return -1;
          return 0;
        });
        this.cities.sort((s, t) => {
          let a = s.city;
          let b = t.city;
          if (a > b) return 1;
          if (b > a) return -1;
          return 0;
        });
      });
      prom.finally(() => {
        if (
          !this.cities.find((element) => {
            return element.city === this.city;
          })
        ) {
          this.city = isFilter
            ? "Все города"
            : localStorage.getItem("city") ?? "Все города";
          this.saveParams();
        }
        if (
          !this.formats.find((element) => {
            return this.format.includes(element);
          })
        ) {
          this.format = isFilter
            ? []
            : localStorage.getItem("format") ?? [];
          this.saveParams();
        }
        this.calcSortedPlaces();
      });
    },
    showSelected() {
      this.showAll = !(this.showAll || this.showFavor);
      this.showFavor = false;
      localStorage.setItem("showAll", this.showAll);
      localStorage.setItem("showFavor", false);
      this.saveParams();
      $(this.changeWindowSize);
      // setTimeout(() => {
      //   this.changeWindowSize();
      // }, 1);
    },
    showFavorites() {
      this.showAll = this.showFavor;
      this.showFavor = !this.showFavor;
      localStorage.setItem("showAll", this.showAll);
      localStorage.setItem("showFavor", this.showFavor);
      this.saveParams();
      $(this.changeWindowSize);
      // setTimeout(() => {
      //   this.changeWindowSize();
      // }, 1);
    },
    saveParams() {
      localStorage.setItem("countVideos", this.countVideos);
      if (this.city === "Все города" || this.city === "" || !this.city) {
        localStorage.removeItem("city");
      } else {
        localStorage.setItem("city", this.city);
      }
      if (this.address === "" || !this.address) {
        localStorage.removeItem("address");
      } else {
        localStorage.setItem("address", this.address);
      }
      if (!this.format.length || this.format === "" || !this.format) {
        localStorage.removeItem("format");
      } else {
        localStorage.setItem("format", this.format);
      }
      let selected = [];
      let favorites = [];
      this.cities.forEach((city) => {
        city.places.forEach((place) => {
          if (place.selected) {
            selected.push(place.index);
          }
          if (place.favor) {
            favorites.push(place.index);
          }
        });
      });
      if (selected.length == 0) {
        localStorage.removeItem("selectedPlaces");
      } else {
        localStorage.setItem("selectedPlaces", selected);
      }
      if (favorites.length == 0) {
        localStorage.removeItem("favorites");
      } else {
        localStorage.setItem("favorites", favorites);
      }
      if (this.filterHidden) {
        localStorage.setItem("filterHidden", true);
      } else {
        localStorage.removeItem("filterHidden");
      }
      if (this.autoShowSec == 60) {
        localStorage.removeItem("autoShowSec");
      } else {
        localStorage.setItem("autoShowSec", this.autoShowSec);
      }
      let params = "";
      if (this.city !== "Все города" && this.city !== "" && this.city) {
        params += "?city=" + this.city;
      }
      if (this.address !== "") {
        params += params.length ? "&address=" : "?address=";
        params += this.address.replace(" ", "+");
      }
      if (this.format.length && this.format !== "" && this.format) {
        params += params.length ? "&format=" : "?format=";
        params += this.format.toString();
      }
      if (this.showFavor || !this.showAll) {
        params += params.length ? "&show=" : "?show=";
        params += this.showFavor ? "favor" : "selected";
      }
      if (this.sort !== "Сортировать по умолчанию") {
        params += params.length ? "&sort=" : "?sort=";
        switch (this.sort) {
          case "Сортировать по адресу":
            params += "address";
            break;
          case "Сортировать по индексу":
            params += "index";
            break;
          case "Сортировать по формату":
            params += "format";
            break;
        }
      }
      window.history.pushState(null, null, "/" + params);
    },
    changeWindowSize() {
      let wiw = window.innerWidth;
      this.$emit("updateShowMenu", wiw <= 790 ? 1 : 0);
      if (wiw <= 600 && !this.miniWidth) {
        this.miniWidth = this.filterHidden = true;
      } else if (wiw > 600 && this.miniWidth) {
        this.miniWidth = this.filterHidden = false;
        document.querySelector(".videoContainer").style.flexGrow = 3;
        $(this.changeWindowSize);
        // setTimeout(() => {
        //   this.changeWindowSize();
        // }, 1);
        return;
      }

      // **********************************************************************
      // ********************* Расчёт размеров содержимого ********************

      let app = document.querySelector("#app"),
        flexMain = document.querySelector(".flexMain"),
        mainMenu = document.querySelector(".mainMenu"),
        nav = document.querySelector("nav"),
        videoBlock = document.querySelector(".videoBlock"),
        video = document.querySelector(".video"),
        videoTitle = document.querySelector(".videoTitle"),
        videoSignature = document.querySelector(".signature"),
        footer = document.querySelector(".footer"),
        filter = document.querySelector(".filter");

      let apt = parseInt(window.getComputedStyle(app).paddingTop),
        mmmt = mainMenu ? parseInt(window.getComputedStyle(mainMenu).marginTop) : 0,
        mmh = mainMenu ? mainMenu.scrollHeight : 0,
        mmmb = mainMenu ? parseInt(window.getComputedStyle(mainMenu).marginBottom) : 0,
        nmb = nav ? parseInt(window.getComputedStyle(nav).marginBottom) : 0,
        vbmt = parseInt(window.getComputedStyle(videoBlock).marginTop),
        vpt = video ? parseInt(window.getComputedStyle(video).paddingTop) : 0,
        vth = video ? videoTitle.scrollHeight : 0,
        mvph = 90, // принимаем минимальную высоту видеоплеера
        mvpw = 160, // принимаем минимальную ширину видеоплеера
        vsmt = video
          ? parseInt(window.getComputedStyle(videoSignature).marginTop)
          : 0,
        vsh = video ? videoSignature.scrollHeight : 0,
        vpb = video
          ? parseInt(window.getComputedStyle(video).paddingBottom)
          : 0,
        vmb = video ? parseInt(window.getComputedStyle(video).marginBottom) : 0,
        vbmb = parseInt(window.getComputedStyle(videoBlock).marginBottom),
        fmt = parseInt(window.getComputedStyle(footer).marginTop),
        fh = footer.scrollHeight,
        fmb = parseInt(window.getComputedStyle(footer).marginBottom),
        apb = parseInt(window.getComputedStyle(app).paddingBottom);

      let fmMaxHeight =
        window.innerHeight -
        mmh -
        apt /*app.paddingTop*/ -
        apb /*app.paddingBottom*/ -
        mmmt -
        Math.max(mmmb, nmb);
      let fmMinHeight =
        vbmt +
        vpt +
        vth +
        mvph +
        vsmt +
        vsh +
        vpb +
        vmb +
        vbmb +
        fmt +
        fh +
        fmb;
      let vpMaxHeight =
          Math.max(fmMaxHeight, fmMinHeight) - (fmMinHeight - mvph),
        vpMaxWidth = Math.floor((vpMaxHeight / 9) * 16);
      flexMain.style.maxHeight = Math.max(fmMaxHeight, fmMinHeight) + "px";

      if (!this.miniWidth || this.filterHidden || this.fullScreen) {
        let filterHidden = this.filterHidden || this.fullScreen,
          fml = filterHidden
            ? 0
            : parseInt(window.getComputedStyle(filter).marginLeft),
          fbl = filterHidden
            ? 0
            : parseInt(window.getComputedStyle(filter).borderLeftWidth),
          fw = filterHidden ? 0 : filter.scrollWidth,
          fbr = filterHidden
            ? 0
            : parseInt(window.getComputedStyle(filter).borderRightWidth),
          fmr = filterHidden
            ? 0
            : parseInt(window.getComputedStyle(filter).marginLeft),
          vbpr = parseInt(window.getComputedStyle(videoBlock).paddingRight),
          vpl = video
            ? parseInt(window.getComputedStyle(video).paddingLeft)
            : 0,
          vpr = video
            ? parseInt(window.getComputedStyle(video).paddingRight)
            : 0,
          fmw = flexMain.scrollWidth;

        let maxVideoBlockWidth = fmw - (fml + fbl + fw + fbr + fmr + vbpr);
        this.maxCountVideos = Math.min(
          5,
          Math.max(
            1,
            Math.floor((0.99 * maxVideoBlockWidth - vbpr) / (mvpw + vpl + vpr))
          )
        );
        if (this.countVideos > this.maxCountVideos) {
          this.countVideos = this.maxCountVideos;
        }

        let mvbw = Math.min(
          maxVideoBlockWidth,
          (vpMaxWidth + vpl + vpr) * this.countVideos + vbpr
        );
        videoBlock.style.maxWidth = mvbw + "px";
        let videoWidth = 99 / this.countVideos + "%";
        for (var ssNum in document.styleSheets) {
          try {
            for (var ruleNum in document.styleSheets[ssNum].cssRules) {
              if (
                document.styleSheets[ssNum].cssRules[ruleNum].selectorText &&
                document.styleSheets[ssNum].cssRules[
                  ruleNum
                ].selectorText.indexOf(".video[") == 0
              ) {
                document.styleSheets[ssNum].cssRules[ruleNum].style.width =
                  videoWidth;
              }
            }
          } catch {
            //
          }
        }

        vth = video ? videoTitle.scrollHeight : 0;
        let vch = Math.max(fmMaxHeight, fmMinHeight),
          vpw = Math.floor(
            ((mvbw - vbpr) * 0.99) / this.countVideos - vpl - vpr
          ),
          vph = Math.floor((vpw / 16) * 9),
          vh = vph + vth + vsh + vpt + vpb + vsmt;
        this.qtyStrings = Math.max(
          1,
          Math.floor((vch - vbmt - vbmb - fh - fmt - fmb) / (vh + vmb))
        );
      }
      document.querySelector(".buttonFilter").style.bottom =
        (flexMain.scrollHeight > 125
          ? fh + fmt + fmb
          : flexMain.scrollHeight > 100
          ? 26
          : 2) + "px";
    },
    toggleFavor(place) {
      place.favor = !place.favor;
      this.saveParams();
    },
    gotoPlace(place, instantly = false) {
      if (!place) {
        return;
      }
      let position = 0;
      let i = 0;
      do {
        let city = this.sortedPlaces[i];
        position +=
          city.places.indexOf(place) === -1
            ? city.places.length
            : city.places.indexOf(place);
      } while (this.sortedPlaces[i++].places.indexOf(place) === -1);
      this.page = Math.floor(position / this.countVideos / this.qtyStrings) + 1;
      if (this.miniWidth && instantly) {
        this.filterHidden = true;
        document.querySelector(".videoContainer").style.flexGrow = 3;
      }
    },
    toggleFilterHidden() {
      this.filterHidden = !this.filterHidden;
      document.querySelector(".videoContainer").style.flexGrow =
        this.miniWidth && !this.filterHidden ? 0.05 : 3;
    },
    getCurrentPlace(sort = null) {
      let visibledPlaces = [];
      this.cities.forEach((city) => {
        city.places.forEach((place) => {
          if (place.visible) {
            visibledPlaces.push(place);
          }
        });
      });
      visibledPlaces.sort((s, t) => {
        let a, b, ta, tb;
        switch (sort ?? this.sort) {
          case "Сортировать по умолчанию":
            a = s.id;
            b = t.id;
            break;
          case "Сортировать по индексу":
            a = s.index.toLowerCase();
            b = t.index.toLowerCase();
            break;
          case "Сортировать по адресу":
            a = s.address.toLowerCase();
            b = t.address.toLowerCase();
            break;
          case "Сортировать по формату":
            ta = s.format.split("x");
            tb = t.format.split("x");
            a = ta[0] * ta[1];
            b = tb[0] * tb[1];
            break;
        }
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      });
      return visibledPlaces[0];
    },
    startShow() {
      this.autoShow = true;
      this.tAutoShow = setInterval((el = this) => {
        el.curProgress++;
        if(el.curProgress == el.autoShowSec) {
          el.curProgress = 0;
          if (el.page == el.countPages) {
            el.page = 1;
          } else {
            el.page++;
          }
        }
      }, 1000);
    },
    stopShow() {
      this.autoShow = false;
      this.curProgress = 0;
      clearInterval(this.tAutoShow);
    },
    setShowPreview() {
      this.showPreview = !this.showPreview;
      if (!this.showPreview) {
        localStorage.setItem("showPreview", false);
      } else {
        localStorage.removeItem("showPreview");
      }
    },
    navigation(event) {
      if (document.activeElement.name !== "address" && !(event.shiftKey || event.ctrlKey || event.altKey || event.etaKey)) {
        if (event.code === "ArrowLeft" && this.page > 1) this.page--;
        if (event.code === "ArrowRight" && this.page < this.countPages) this.page++;
      }
      if (event.ctrlKey && !(event.shiftKey || event.altKey || event.etaKey) && event.code === "ArrowDown" || 
        event.code === "Escape" && !(event.shiftKey || event.ctrlKey || event.altKey || event.etaKey) && this.fullScreen) {
          this.fullScreen = !this.fullScreen;
          this.$emit("toggleFullScreen", this.fullScreen);
          setTimeout(() => {
            this.changeWindowSize();
            }, 1);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.address {
  padding-left: 5px;
  padding-right: 5px;
}

.buttonFilter {
  margin-left: -10px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
  opacity: 0.33;
  transition-duration: 0.8s;
  transition-property: opacity;
}

.buttonFilter:hover {
  opacity: 1;
}

.filter {
  border: 2px solid rgb(73, 72, 72);
  position: relative;
  padding: 10px;
  font-size: 1.25em;
  min-width: 220px;
  margin: 10px;
  flex: 1;
  display: flex;
  flex-flow: column nowrap;
}

.filter .city {
  text-decoration: none;
  background: rgb(35, 35, 35);
  border-radius: 4px;
  display: block;
  color: #eee;
  font-size: 1em;
  margin-top: 6px;
  text-align: left;
  padding: 5px 0 5px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter .city:hover {
  color: rgb(250, 234, 10);
}

.filterField {
  width: 100%;
  font-size: 0.8em;
  text-align: left;
  height: 25px;
  margin: 2px auto;
  -ms-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid rgb(211, 211, 211);
}

.flexMain {
  display: flex;
  flex-flow: row nowrap;
  background-color: white;
}

.footer {
  border-top: 1px solid rgba(2, 2, 2, 0.2);
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto 0;
  padding: 10px;
  color: black;
  background-color: white;
}

.listCities {
  margin-top: 5px;
  position: relative;
  overflow: auto;
}

.listPlaces {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background: #fff;
  text-align: left;
  font-size: 0.75em;
  padding-top: 2px;
  margin-top: 2px;
  color: black;
  overflow: hidden;
}

.listPlaces img {
  display: none;
  position: absolute;
  float: right;
  right: 3px;
  width: 18px;
  margin-top: -17px;
}

.listPlaces .favor {
  display: inherit;
  cursor: pointer;
}

.places {
  background: #eee;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
  padding: 1px;
  padding-right: 21px;
}

.places:hover {
  padding-right: 42px;
}

.places:hover > img {
  display: inherit;
  cursor: pointer;
}

.places strong {
  width: 5em;
  margin-right: 3px;
  margin-left: 3px;
}

.places .eye {
  right: 24px;
  margin-top: -15px;
}

.signature {
  color: white;
  text-align: start;
  font-size: 1em;
  margin-top: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.video {
  display: flex;
  flex-flow: column nowrap;
  background: #222;
  margin: 0 auto 5px;
  padding: 10px;
  border: 1px solid white;
}

.video input {
  text-align: left;
}

.video label {
  color: white;
  padding-left: 5px;
  padding-bottom: 5px;
  width: 100%;
}

.videoBlock {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin: 10px auto;
  padding-right: 5px;
}

.videoContainer {
  display: flex;
  flex-flow: column nowrap;
  flex: 2.5;
  justify-content: space-between;
  background-color: #212121;
}

.videoTitle {
  display: flex;
  flex: 1;
}

.videoTitle img {
  position: relative;
  top: -5px;
  right: 3px;
  width: 18px;
  cursor: pointer;
}

.visiblePlace {
  background-color: #ccc;
}

.buttons {
  flex: 1.3;
}

.showSelected {
  background-color: #FFED00;
  padding: 8px;
  margin: 5px 2px;
  float: left;
  font-weight: bold;
  font-size: 0.8em;
  letter-spacing: 0.2ex;
  border-radius: 4px;
  border: 1px solid rgb(110, 114, 57);
  width: 48%;
}

.pagination {
  flex: 2;
  display: block;
}

.paginationMini {
  display: none;
}

.progress {
  margin-top: 1px;
  cursor: pointer;
}

.progress {
  color: blue;
}

.progress b {
  color: white;
}

.progress:hover b {
  color: blue; 
}

.autoShow {
  cursor: pointer;
}

.preview {
  cursor: pointer;
  padding-right: 5px;
  padding-top: 3px;
}

.footer label {
  flex: 1;
  text-align: right;
  font-size: 0.8em;
  display: flex;
  justify-content: flex-end;
}

.footer select {
  margin: auto 5px;
  border: 1px solid #C4C4C4;
  height: 24px;
  border-radius: 2px;
}

@media (max-width: 1620px) {
  .pagination {
    zoom: 0.8;
    flex: 1.5;
  }
}

@media (max-width: 1450px) {
  .pagination {
    display: none;
  }

  .paginationMini {
    display: initial;
    flex: 0.5;
  }
}

@media (max-width: 1220px) {
  .paginationMini {
    display: none;
  }

  .filter {
    font-size: 0.8em;
    flex: 1;
  }

  .listPlaces img {
    width: 15px;
    margin-top: -15px;
  }

  .places .eye {
    right: 20px;
    margin-top: -13px;
  }

  .videoContainer {
    flex: 3;
    font-size: 0.8em;
  }

  .footer {
    flex-flow: column nowrap;
    margin: 5px auto;
  }

  .footer label {
    margin-top: 4px;
  }

  .pagination {
    display: initial;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .showSelected {
    background-color: yellow;
    padding: 8px;
    margin: 0 2px;
    float: none;
    font-weight: bold;
    font-size: 0.8em;
    letter-spacing: 0.1ex;
    border-radius: 4px;
    flex: 1;
  }
}
</style>