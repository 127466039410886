<template>
  <div>
    <video ref="videoPlayer" class="video-js vjs-fluid vjs-16-9" @click="setFullWindow"></video>
  </div>
</template>

<script>
import videojs from "video.js";

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      player: null,
      timer: null,
    };
  },
  mounted() {
    let self = this;
    this.player = videojs(
      this.$refs.videoPlayer,
      this.options,
      function onPlayerReady() {
        self.$emit("playerReady");
      }
    );
    this.timer = setInterval((player = this.player) => {
      if (player.readyState() !== 4) {
        let source = {
          src: this.options.sources[0].src,
          type: this.options.sources[0].type
        };
        player.src(source);
        console.log("Player with src = '" + source.src + "' restarted!");
      }
    }, 15000);
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  watch: {
    options: function name(params) {
      this.player.src(params.sources);
      this.player.poster(params.poster);
      if(!params.sources[0].src) {
        this.player.load();
      }
    }
  },
  methods: {
    setFullWindow() {
      if (this.player.isFullWindow) {
        this.player.exitFullWindow();
      } else {
        this.player.enterFullWindow();
      }
    }
  },
};
</script>