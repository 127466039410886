<template>
  <div id="app">
    <a v-if="!fullScreen" href="http://dmaster.ru"><img src="/images/logo.png" class="logo"/></a>
    <button class="button" v-if="showMenu && !fullScreen" @click="toggleShowMenu">
      <div class="button__line button__line--horisontal-first" v-if="showMenu == 1"></div>
      <div class="button__line button__line--horisontal-second" v-if="showMenu == 1"></div>
      <div class="button__line button__line--horisontal-third" v-if="showMenu == 1"></div>

      <div class="button__line button__line--diagonal-first" v-if="showMenu == 2"></div>
      <div class="button__line button__line--diagonal-second" v-if="showMenu == 2"></div>
    </button>
    <div class="mainMenu" v-if="!fullScreen">
      <img src="/images/designMaster.png" class="DM">
      <b> ВИДЕОНАБЛЮДЕНИЕ</b>
      <nav v-if="showMenu != 1">
        <a href="https://dmaster.ru/about_us">о&nbsp;компании</a>
        <a href="https://dmaster.ru/services">продукты</a>
        <a href="https://dmaster.ru/news">новости</a>
        <a href="https://dmaster.ru/contacts">контакты</a>
        <a href="https://bookingboard.ru/plan/">адресная&nbsp;программа</a>
      </nav>
    </div>
    <Broadcast @updateShowMenu="onUpdateShowMenu" @toggleFullScreen="onToggleFullScreen"></Broadcast>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/index.css'
import '../src/css/element-ui.css'

import Vue from 'vue'
import Broadcast from "./components/Broadcast.vue";
import ElementUI from 'element-ui';
const locale = require('element-ui/lib/locale/lang/ru-RU.js').default;

Vue.use(ElementUI, { locale });

export default {
  name: "App",
  components: {
    Broadcast,
  },
  data() {
    return {
      showMenu: 0,
      fullScreen: false,
    };
  },
  methods: {
    toggleShowMenu() {
      this.showMenu = this.showMenu == 1 ? 2 : 1;
    },
    onUpdateShowMenu(newValue) {
      if (newValue == 0) {
        this.showMenu = 0;
      } else if (this.showMenu == 0) {
        this.showMenu = 1;
      }
    },
    onToggleFullScreen(value) {
      this.fullScreen = value;
      document.getElementById("app").style.width = this.fullScreen ? "100%" : "89.2%";
      document.getElementById('html').setAttribute('style', !this.fullScreen ? 'background-image: url("/images/background.jpg");' : 'background-image: url();')
    }
  },
};
</script>

<style>
.button {
  /* Reset */
  background-color: transparent;
  border-color: transparent;

  /* Cursor */
  cursor: pointer;

  /* Size */
  height: 32px;
  width: 32px;

  /* Used to position the inner */
  position: absolute;
  right: 15px;
  top: 8px;
}

.button__line {
  /* Background color */
  background-color: rgb(255, 255, 255);

  /* Position */
  position: absolute;

  /* Size */
  height: 1px;
  width: 100%;
}

.button:hover .button__line {
  background-color: #FFED00;
}

.button__line--diagonal-first {
  /* Position */
  left: 0;
  top: 50%;
  transform: translate(0%, -50%) rotate(45deg);

  /* Size */
  height: 3px;
  width: 100%;
}

.button__line--diagonal-second {
  /* Position */
  left: 50%;
  top: 0;
  transform: translate(-50%, 0%) rotate(45deg);

  /* Size */
  height: 100%;
  width: 3px;
}

.button__line--horisontal-first {
  /* Position */
  left: 4px;
  top: 5px;
  transform: translate(0%, -50%);

  /* Size */
  height: 3px;
  width: 25px;
}

.button__line--horisontal-second {
  /* Position */
  left: 4px;
  top: 50%;
  transform: translate(0%, -50%);

  /* Size */
  height: 3px;
  width: 25px;
}

.button__line--horisontal-third {
  /* Position */
  left: 4px;
  top: calc(100% - 5px);
  transform: translate(0%, -50%);

  /* Size */
  height: 3px;
  width: 25px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  position: relative;
  width: 89.2%;
  margin: 0 auto;
  padding: 10px;
}

.mainMenu b {
  font-style: normal;
  color: #FFED00;
  font-size: 1.1em;
  letter-spacing: 2px;
  padding: 8px;
}

.mainMenu {
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}

#app .logo {
  width: 25px;
  position: relative;
  float: left;
  margin: 10px auto 5px 15px;
  transition-duration: .8s;
}

#app .logo:hover {
  transition-duration: .8s;
  transform: scale(1.2) rotate(720deg);
}

#app .DM {
  margin-right: 5px;
  width: 200px;
  margin-bottom: -4px;
}

.mainMenu nav {
  margin: 15px;
}

.mainMenu nav a {
  color: white;
  text-transform: uppercase;
  margin: 10px;
  font-family: 'Open Sans';
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 3px;
}

.mainMenu nav a:visited {
  color: #BBB;
}

.mainMenu nav a:hover {
  color: #FFED00;
}

.mainMenu nav a:visited:hover {
  color: #FFED00;
}

@media (max-width: 1220px) {
  #app {
    padding: 2px;
    width: auto;
  }
}

@media (max-width: 790px) {
  #app .logo {
    width: 15px;
  }

  .mainMenu {
    width: 85%;
    margin-bottom: 15px;
    padding-top: 5px;
  }

  .mainMenu nav {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    z-index: 2;
    background-color: rgba(7, 21, 30, 0.93);
    width: 100%;
    margin: 0;
    margin-top: 5px;
    left: 0;
  }
}
</style>